import Vue from "vue";
import Router from "vue-router";

Vue.use(Router)


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const router = new Router({
    routes: [{
            path: '/login',
            component: resolve => require(['@/pages/login'], resolve),
            meta: {
                title: '登录',
                keepAlive: false
            },
        },
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/baseIndex',
            name: '主页',
            component: resolve => require(['@/pages/baseIndex'], resolve),
            meta: {
                title: '主页',
                keepAlive: false
            },
            redirect: '/MessagePanel',
            children: [{
                    path: '/MessagePanel',
                    component: resolve => require(['@/pages/MessagePanel'], resolve),
                    meta: {
                        title: '菜单管理',
                        keepAlive: false
                    },
                },


            ]

        },

    ]
})
// router.beforeEach((to, from, next) => {
//     let toDepth = to.path.split("/").length;
//     let fromDepth = from.path.split("/").length;
//     console.log(toDepth)
//     console.log(fromDepth)
//     if (toDepth < fromDepth) {
//         // console.log('back...')
//         from.meta.keepAlive = false;
//         to.meta.keepAlive = true;
//     }

//     next();

// })

export default router