import axios from "axios";
import qs from 'qs'
import common from './comm'
import router from '../../router'


import {
    Message
} from 'element-ui'
// import router from '../../router'
import {
    showLoading,
    hideLoading
} from './loading'
import tools from './tool'

const baseURL = common.baseURL; //服务器地址

axios.defaults.baseURL = `${baseURL}`;
axios.defaults.timeout = 1000000;

// axios.defaults.headers['ClientHost'] = tools.getDomainName();
axios.defaults.headers['LanguageCode'] = tools.getLanguageCode();
axios.defaults.headers['Device'] = 'admin_h5:app_status'; //应用标识
// axios.defaults.headers['Device'] = 'admin:app_status'; //应用标识

function axios_requer(path, params, methods, headerType = '') {
    let that = this;
    let method = methods || 'GET'
    let data = params;
    let baseURL = baseURL;
    //设置请求头
    if (method === 'post') { //post请求
        if (headerType === "json") {
            axios.defaults.headers["Content-Type"] = 'application/json;charset=UTF-8'
            data = params
        } else {
            axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded;charset=UTF-8'
            data = qs.stringify(params);
        }
    }

    //get请求
    if (method === 'GET') {
        axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded;charset=UTF-8'
        data = qs.stringify(params);
        if (data) {
            path = path + '?' + data
        }
    }
    showLoading()
    // 请求拦截
    axios.interceptors.request.use((config) => {
        config.headers['UserKey'] = window.localStorage.UserKey || '';
        return config;
    }, (error) => {
        hideLoading();
        return error;
    });

    //响应拦截
    axios.interceptors.response.use(response => {
        hideLoading();
        return response;
    }, error => {
        hideLoading();
        return error
    })

    //发送请求
    return new Promise((resolve, reject) => {
        axios({
            baseURL,
            method,
            url: path,
            data: data,
        }).then((result) => {
            if (result.data.status === 'error') {
                Message.error(result.data.message || '');

                // if (result.data.message === '没有访问权限~') {
                //     this.$router.replace('@/pages/login')
                // }
                let jump = result.data.jump || '';
                if (jump) {
                    if (jump === 'login' || jump === 'apply') {
                        router.push({
                            path: '/login'
                        })
                        window.localStorage.setItem('ifReLogin', true)
                        return
                    }
                    // if (jump == 'apply') {
                    //     //没有权限
                    //     router.replace('/login')
                    // }
                    let reg = new RegExp(/http[s]{0,1}:\/\//);
                    let flag = reg.test(jump);
                    if (flag) {
                        window.location.href = jump
                    }
                }
                reject(result)
            } else {
                resolve(result)
            }

        }).catch(err => {
            console.log(err)
            // if (err.data.status == 'error') {
            //     Message.error(err.data.message)
            // }

            reject(err)
        })
    })

}

// 文件上传
function axios_file(path, param, method_s) {
    axios.defaults.headers["Content-Type"] = 'multipart/form-data';
    axios.interceptors.request.use(function (config) {
        config.headers['UserKey'] = window.localStorage.UserKey;
        return config;
    }, function (error) {
        hideLoading();
        return error;
    });
    let method = method_s || 'POST';
    //发送请求
    return new Promise((resolve, reject) => {
        axios({
            baseURL,
            method,
            url: path,
            data: param,
        }).then(result => {

            if (result.data.status == 'error') {
                Message.error(result.data.message || '');

                let jump = result.data.jump || '';
                if (jump) {
                    if (jump == 'login') {
                        this.$router.push({
                            path: '/login'
                        })
                        window.localStorage.setItem('ifReLogin', true)
                        return
                    }
                    if (jump == 'apply') {
                        //没有权限
                        // uni.reLaunch({
                        // 	url: '/pages/login/login'
                        // })
                        // return
                    }
                    let reg = new RegExp(/http[s]{0,1}:\/\//);
                    let flag = reg.test(jump);
                    if (flag) {
                        window.location.href = jump
                    }

                }
            }
            resolve(result)
        }).catch(err => {
            reject(err)
        })
    })
}


export {
    axios_requer,
    axios_file,
}
