import Vue from 'vue'
import VueRouter from 'vue-router';
import router from './router';
import ElementUI from 'element-ui';
import '@/assets/js/http_axios'
import 'element-ui/lib/theme-chalk/index.css';
import common from '@/assets/js/comm'
import App from './App.vue'
import 'default-passive-events'
import Print from '@/assets/js/print.js'
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(Print);

Vue.prototype.common = common;

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
Vue.prototype.$keyBoard = function (vm, methodName, code) {
  document.onkeydown = function () {
    let key = window.event.keyCode;
    if (key == code) {
      vm[methodName](code); // 触发methodName事件
    }
  };
}