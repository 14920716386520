// import tools from './tool'

// const baseURL = 'http://192.168.1.28:80'; //老陈ip
// const baseURL = 'http://192.168.1.5:8080'; //梁列
// const baseURL = 'http://594e6bde.cpolar.io'; //罗
let domain = '';
// const baseURL = 'http://192.168.1.37:8080'; //梁

// const baseURL = 'https://dev.b9.api.8bami.com'; //测试服务器
// const baseURL = 'https://dev.gjtms.common.8bami.com'; //测试服务器 (最新)
// 正式
const baseURL = 'https://b9.api.8bami.com'; //正式服务器


// domain = 'live.admin.bamibuy.com' //八米购 正式
// domain = 'btgj.admin.xhyitsoft.com'  //货巢
// domain = 'b9.admin.xhyitsoft.com' //八米
// domain = 'hoomoo.admin.8bami.com' //货猫
// domain = 'bamibuy.admin.8bami.com' //八米购

const loginOpen = false //true==申请试用 ,false==正常登录(八米跨境)
let serve_s = 'build' //dev==开发环境 ,build==发布build

if (process.env.NODE_ENV === "development") {
    // 开发环境
    serve_s = 'dev'
} else {
    serve_s = 'build'
    // alert("生产环境");
}
console.log(serve_s)

const uploadUrl = baseURL + '/base/saas/v1/file/upload'; //文件上传接口
const prefix = 'pc_prefix' //短信前缀
const appid = 'hy7vptfjxs6zhwsowz';
const appsecret = '1f29443847dabd29ef06c319f21fcecf';

export default {
    baseURL,
    appid,
    appsecret,
    uploadUrl,
    prefix,
    serve_s,
    domain,
    loginOpen,
}
