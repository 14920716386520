import md5 from './md5.js'
import Base64 from './base64.js'
import common from './comm.js'
import router from '../../router'

// appid和密钥
let appid = common.appid;
let appsecret = common.appsecret;

// 获取域名
function getDomainName() {
    let domain = '';
    // let domain = location.host || '';
    // uni.setStorageSync('domainName', domain);
    if (common.serve_s == 'dev') {
        domain = common.domain || ''

    }
    return domain
}
// 获取当前语言代码
function getLanguageCode() {
    let code = navigator.language || navigator.userLanguage;
    if (common.serve_s == 'dev') {
        code = 'zh-CN'
    }
    return code
}

/**
 * 获取浏览器url中的参数
 */
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return '';
}

// 处理参数,变成我想要的对象数组
function getSignArr(param) {

    let arr = [];
    for (let k in param) {
        let obj = {
            key: k,
            val: param[k],
        };
        arr.push(obj);
    }
    return arr
}


// 获取md5加密签名(第一种,弃用,有部分接口已经用了就不改了,要传个对象数组[{key:'name',val:'张三'},]没写好有点麻烦)
function getMD5Sign(param) {

    var sign = appsecret;
    //排序
    let datas = param || [];
    let sortArr = param || [];
    var newArr = [];
    for (var j = 0; j < sortArr.length; j++) {
        newArr.push(sortArr[j].key)
    }
    let arrSort = newArr.sort();
    let endArr = [];
    for (var s = 0; s < arrSort.length; s++) {
        for (var d = 0; d < datas.length; d++) {
            if (arrSort[s] == datas[d].key) {
                endArr.push(datas[d])
            }
        }
    }

    if (endArr.length == 0) {
        return
    }

    for (var i = 0; i < endArr.length; i++) {
        let val1 = endArr[i].val;
        if (val1 === 0 || val1 ==='0') {

        } else {
            val1 = endArr[i].val || ''
        }
        let val = JSON.stringify(val1);
        if (!isJSON(val)) {
            val = val1 + ''
        } else {
            val = 'object'
        }
        sign = md5.hex_md5(endArr[i].key + getCharCodeArr(val) + sign);
    }
    sign = md5.hex_md5(sign + appid)
    return sign //
}
// 获取md5加密签名(第二种,用这个,直接传个对象{name:'张三'})
function getSign(param) {
    let arrs = [];
    for (let k in param) {
        let val1 = param[k];
        if (val1 === 0 || val1 ==='0') {

        } else {
            val1 = param[k] || ''
        }
        let obj = {
            key: k,
            val: val1,
        };
        arrs.push(obj);
    }
    var sign = appsecret;
    //排序
    let datas = arrs || [];
    let sortArr = arrs || [];
    var newArr = [];
    for (var j = 0; j < sortArr.length; j++) {
        newArr.push(sortArr[j].key)
    }
    let arrSort = newArr.sort();
    let endArr = [];
    for (var s = 0; s < arrSort.length; s++) {
        for (var d = 0; d < datas.length; d++) {
            if (arrSort[s] == datas[d].key) {
                endArr.push(datas[d])
            }
        }
    }

    if (endArr.length == 0) {
        return
    }

    for (var i = 0; i < endArr.length; i++) {
        let val = JSON.stringify(endArr[i].val);
        if (!isJSON(val)) {
            val = endArr[i].val + '';
        } else {
            val = 'object'
        }
        console.log(i + '_' + i + '   ' + 'sign:=====' + sign + '>>>>>>>> key:====' + endArr[i].key + '>>>>>>>> val:====' + val)
        sign = md5.hex_md5(endArr[i].key + getCharCodeArr(val) + sign);
        console.log('第' + i + '次的sign :  ' + sign)
    }
    sign = md5.hex_md5(sign + appid)
    // console.log('最终结果:  ' + sign)
    return sign //
}
// 判断是否是jsop
function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
}
function getCharCodeArr(str) {
    let strArr = str.split("");
    let strEnd = "";
    for (var i = 0; i < strArr.length; i++) {
        let code = strArr[i].charCodeAt();
        strEnd = strEnd + "" + code;
    }
    return strEnd
}
//下载.xlsx格式
function downloadFile(downloadurl) { //
    let url = window.URL.createObjectURL(new Blob([downloadurl]));
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", "bami_jy_export_Template.xlsx"); //指定下载后的文件名，防跳转
    document.body.appendChild(link);
    link.click();
}

//当前年月日时分秒
function getCurrentTime() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();

    month = month < 10 ? ('0' + month) : month;
    day = day < 10 ? ('0' + day) : day;
    let sdate = year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
    return sdate
}
//当前年月日
function getCurrentDate() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month < 10 ? ('0' + month) : month;
    day = day < 10 ? ('0' + day) : day;
    let sdate = year + '-' + month + '-' + day;
    return sdate
}

// 获取前num天或者后num天
function getFlexDate(num) {
    let num1 = num || 0;
    var date1 = new Date(), //今天
        date2 = new Date(date1);
    date2.setDate(date1.getDate() + num1); //今天的前或者后几天
    // console.log('date2-----' + date2);

    var fixedM = date2.getMonth() + 1,
        fixedD = date2.getDate();

    fixedM = fixedM < 10 ? '0' + fixedM : fixedM;
    fixedD = fixedD < 10 ? '0' + fixedD : fixedD;
    var time2 = date2.getFullYear() + "-" + fixedM + "-" + fixedD;
    return time2
}
//根据非数字字母切割多个单号
function getOrderNum(srt) {
    var String = [];
    var NewArr = [];
    if (srt.length < 1) {
        console.log('return')
        return NewArr;

    }
    console.log('return12323')
    let flag = /[^0-9A-Za-z]+/;
    String = srt.split(flag);

    for (var i = 0; i < String.length; i++) {
        if (String[i]) {
            NewArr.push(String[i])
        }
    }
    return NewArr;
}


// ==========================精度计算 开始==============================================
/*
 * 判断obj是否为一个整数
 */
function isInteger(obj) {
    return Math.floor(obj) === obj
}

/*
 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
 * @param floatNum {number} 小数
 * @return {object}
 *   {times:100, num: 314}
 */
function toInteger(floatNum) {
    var ret = {
        times: 1,
        num: 0
    };
    if (isInteger(floatNum)) {
        ret.num = floatNum;
        return ret
    }
    var strfi = floatNum + ''; //转字符串
    var dotPos = strfi.indexOf('.'); //获取小数点下标
    var len = strfi.substr(dotPos + 1).length; //小数点后有几位
    var times = Math.pow(10, len); //10的len次方
    var intNum = parseInt(floatNum * times + 0.5, 10);
    ret.times = times;
    ret.num = intNum;
    return ret
}

/*
 * 核心方法，实现加减乘除运算，确保不丢失精度
 * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
 *
 * @param a {number} 运算数1
 * @param b {number} 运算数2
 * @param op {string} 运算类型，有加减乘除（add/sub/mul/divide）
 *
 */
function operation(a, b, op) {
    var o1 = toInteger(a);
    var o2 = toInteger(b);
    var n1 = o1.num;
    var n2 = o2.num;
    var t1 = o1.times;
    var t2 = o2.times;
    var max = t1 > t2 ? t1 : t2;
    var result = null;
    switch (op) {
        case 'add': //加法
            if (t1 === t2) { // 两个小数位数相同
                result = n1 + n2
            } else if (t1 > t2) { // o1 小数位 大于 o2
                result = n1 + n2 * (t1 / t2)
            } else { // o1 小数位 小于 o2
                result = n1 * (t2 / t1) + n2
            }
            return result / max;
        case 'sub': //减法
            if (t1 === t2) {
                result = n1 - n2
            } else if (t1 > t2) {
                result = n1 - n2 * (t1 / t2)
            } else {
                result = n1 * (t2 / t1) - n2
            }
            return result / max;
        case 'mul': //乘法
            result = (n1 * n2) / (t1 * t2);
            return result;
        case 'divide': //除法
            result = (n1 / n2) * (t2 / t1);
            return result
    }
}

// 加
function add(a, b) {
    return operation(a, b, 'add')
}
//减
function sub(a, b) {
    return operation(a, b, 'sub')
}
//乘
function mul(a, b) {
    return operation(a, b, 'mul')
}
//除
function divide(a, b) {
    return operation(a, b, 'divide')
}
// 四合五入  (计算金额用这个)
function mathRound(number) {
    return Math.round(number * 100) / 100 // 成功转成1.54
}
// 保留几位小数    (偶尔会出现没有四舍五入的问题,例如谷歌浏览器下 1.535 不会四舍五入,1.635.toFixed(2)可以)
function mathToFixed(number, num) {
    return number.toFixed(num) // 成功转成1.54
}
// ==========================精度计算 结束==============================================


//关闭当前页面,跳转指定页面 (this.reload()要在具体页面用)
function closePage(goRoute) {
    // console.log(router)
    // let currentRoute = router.fullPath
    let currentRoute = router.history.current.fullPath;
    if (currentRoute.indexOf('?') !== -1) {
        currentRoute = currentRoute.substr(0, currentRoute.indexOf('?'))
    }
    console.log(currentRoute)
    let arrs = JSON.parse(window.sessionStorage.editableTabs);
    arrs.forEach((item, index) => {
        if (item.path == currentRoute) {
            arrs.splice(index, 1);
        }
    });
    window.sessionStorage.setItem("editableTabs", JSON.stringify(arrs));
    if (goRoute) {
        router.push(goRoute);
    } else {
        router.go(-1)
    }
    // this.reload();
}


//把请求方法暴露出去
export default {
    getDomainName,
    getLanguageCode,
    getUrlParam,
    getMD5Sign,
    downloadFile,
    getSignArr,
    getFlexDate,
    getCurrentTime,
    getCurrentDate,
    getSign,
    getOrderNum,
    mathToFixed,
    mathRound,
    divide,
    mul,
    sub,
    add,
    closePage,
}